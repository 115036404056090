import * as React from 'react';
import magazine from '../../static/colibri-8-web.pdf';
import magazineMobile from '../../static/colibr-8-a4.pdf';
import { useGoatCounter } from 'gatsby-plugin-goatcounter';
import { hidde } from '../styles/index.module.css';

export default function Button() {
  const count = useGoatCounter();

  count({
    path: { magazine },
    event: true,
  });
  return (
    <button>
      <span className={hidde}>
        <a href={magazineMobile}></a>
      </span>
      <a href={magazine} download="Revista-colibrí-08">
        ✨ Descarga nuestro último lanzamiento aquí
      </a>
    </button>
  );
}
