import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639251791/Screenshot_2021-11-24_at_15.00.00_r5h0bo.png",
        "alt": "Tulum-Servan"
      }}></img></p>
    <p>{`El puerto de Zama, mejor conocido como Tulum; hoy en día es una ciudad turística muy importante. Su nombre surge gracias a una de las expediciones de Stephens y Catherwood quienes venían del Reino Unido, en los años de 1840. Viajando por la Península de Yucatán llegaron a una parte de la Costa Este donde preguntaron el nombre a los locales de la zona, en ese entonces los mayas modernos. Probablemente el nombre `}<em parentName="p">{`Tulum`}</em>{` viene del maya yucateco; una de las tantas lenguas de la península. `}<em parentName="p">{`Tun`}</em>{` significa “piedra” y `}<em parentName="p">{`Luum`}</em>{` significa “tierra”, de donde se podría traducir como “la tierra entre piedras” o “la ciudad amurallada” como se le conoce coloquialmente. Cabe mencionar que estos viajes se hicieron famosos porque mostraron las primeras imágenes de la tierra maya para el mundo. Ahora es parte de la Riviera Maya en México, uno de los polos turísticos más importantes de América.`}</p>
    <p>{`El apogeo de Tulum se dio durante el periodo Posclásico mesoamericano: del 900 d.C. hasta la llegada de los conquistadores; aunque fue ocupado desde el periodo Clásico. Era una colonia amurallada y digo colonia porque no tenía una población mayor de 400 habitantes, dentro de la zona amurallada. Fuera de la muralla habitaba la gente común que se dedicaba, principalmente, a la agricultura, caza, pesca y comercio. `}<strong parentName="p">{`Este puerto fue controlado por una la famosa ciudad-estado. de Chichen Itza, en alianza con o tras dos dinastías que gobernaban las ciudades de Uxmal y de Mayapan. Juntos controlaron la Península de Yucatán en los sentidos económico, político y religioso`}</strong>{`. Por ende, las rutas comerciales eran también controladas por ellos. Es importante mencionar que los productos que se exportaban de la Península para el resto de Mesoamérica fueron: miel de la abeja melipona, sal de las costas de Yucatán y el famoso chicle que se obtiene de la resina del árbol de chicozapote (`}<em parentName="p">{`manilcara zapota`}</em>{`).`}</p>
    <p>{`La ubicación geográfica de Zama es estratégica, ya que está rodeada por una zona de manglar por la parte oeste. Por la parte este, que es el mar Caribe, tenemos la barrera arrecifal Mesoamericana, la segunda más grande del mundo después de la gran barrera arrecifal de Australia. Además está la muralla que delimita la zona con sus entradas originales: 2 al norte, 1 al oeste y 2 al sur, todas éstas son muy pequeñas lo que no permite el acceso al mismo tiempo de un grupo grande de personas. Por el lado este se encuentra el mar y se podría suponer que es de fácil acceso por el agua en bote o balsa, pero no. El arrecife funciona como una barrera natural y eso hace no sea tan fácil entrar a Tulum sin encallar en el coral. El Castillo en Tulum presenta varias etapas constructivas: una de ellas funciona como un tipo faro o indicador para alinearse, de tal manera que se pueda entrar sin encallar en el coral. Por esta razón la entrada por el agua se puede controlar. Lo interesante es que no todos saben o sabían cómo funciona. Si vinieras del mar con dirección oeste tendrías que encontrar el castillo, de ahí deberías ubicar una de las ventanas completas, sin perderla de vista, se tiene que encontrar la otra y una vez que se ven las 2 ventanas completas quiere decir que estás alineado y listo para entrar al puerto de Zama. Este es el secreto.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639251794/Screenshot_2021-11-25_at_05.34.26_crwk9c.png",
        "alt": "Zama-Servan"
      }}></img></p>
    <p>{`Ya todos saben que Tulum ha sido una ciudad de comercio, pero quizá `}<strong parentName="p">{`es más interesante considerar a Zama como una ciudad para observar el movimiento del cosmos, en especial algunos astros importantes como el Sol, la Luna, algunos planetas y estrellas`}</strong>{`. Ubicado en ciertos lugares, viendo hacia ciertas direcciones encontrarás algunos templos con pequeñas ventanas. Una de sus funciones era marcar el movimiento de estos astros y de ahí obtener información precisa que ayudaba a los astrónomos a tomar decisiones. La información es poder y ésta se encontraba en manos de algunos cuantos ya que se transmitía de generación en generación. De esta forma podían tener cierto tipo de datos, por ejemplo, cuándo era la mejor época para sembrar o hacer agricultura, cazar ciertos animales o cortar ciertas maderas, también predecir alineaciones de planetas o estrellas y eclipses. Todo lo que nos lleva a dar cuenta de la obsesión que tenían por entender el movimiento del cosmos y tratar de medir el tiempo.`}</p>
    <p>{`Es importante mencionar que varios europeos pasaron por acta, hablaron del lugar y lo describieron. En uno de los viajes de exploración de Hernán Cortés por Cuba, llegaron a Cozumel y, de ahí se mandaban expediciones a explorar la península de Yucatán, en éstas describen a Tulum como una ciudad parecida a Sevilla, España. Pero no pudieron entrar gracias a la barrera arrecifal. De ahí continuaron rodeando la península y trataron de entrar por Champotón, en Campeche. Ahí tienen un a batalla. Para entonces ya estaba Gonzalo Guerrero (el padre del mestizaje) en la zona y se alió con los locales para pelear en contra de las tropas de Hernán Cortés que trataban de entrar por Champotón, razón por la que los conquistadores siguieron su camino hacia el centro con el imperio mexica.`}</p>
    <p><img parentName="p" {...{
        "src": "https://res.cloudinary.com/bethbecerra/image/upload/v1639251795/Screenshot_2021-11-24_at_15.08.22_s3yapf.png",
        "alt": "Tulum-Zama-Servan"
      }}></img></p>
    <p>{`Para concluir, me gustaría recomendarles visitar el sitio arqueológico de Tulum y los increíbles lugares a su alrededor. `}<strong parentName="p">{`Usen mucho la imaginación ya que todos sus templos estaban ricamente decorados con estuco y pintados en rojo, azul maya y amarillo`}</strong>{`, entre otros. De igual manera consigan un guía certificado (P.E. @Servan_guide) pues les ayudará a entender mucho mejor la historia del sitio.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      