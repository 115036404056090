import * as React from 'react';
import Button from '../components/button.js';
import Layout from './layout.js';
import { text, articleAuthor, section } from '../styles/post-layout.module.css';

export default function PostLayout({ children, pageContext }) {
  const { title, description, author, image, path } = pageContext.frontmatter;

  return (
    <Layout
      title={title}
      description={description}
      image={image}
      path={path}
      classNameWrapper={section}
    >
      <section className={section}>
        <h1>{title}</h1>
        <p className={text}>
          <span className={articleAuthor}> Por: {author} </span>
          {children}
        </p>
        <Button />
      </section>
    </Layout>
  );
}
